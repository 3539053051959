import React from 'react';
import './App.css';
import CurvePanel from './Components/CurvePanel';

function App() {
  return (
    <div className="App">
      <CurvePanel />
    </div>
  );
}

export default App;
