import React from 'react';
import './TempCurve.css'

import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    PointElement,
    LineElement,
    TimeScale,
    Title,
    Tooltip,
    Legend
} from "chart.js";

import { Scatter } from 'react-chartjs-2'
import 'chartjs-adapter-moment'
import moment from 'moment';

ChartJS.register(CategoryScale, TimeScale, LinearScale, PointElement, LineElement, Title, Tooltip, Legend);


/**
 * React-Komponente für ein Graph-Widget
 */
class TempCurve extends React.Component {

    render() {

        let y_min = null;
        let y_max = null;
        if (this.props.min_max[this.props.sensor_data.einheit]) {
            y_min = this.props.min_max[this.props.sensor_data.einheit].min;
            y_max = this.props.min_max[this.props.sensor_data.einheit].max
        }

        if (this.props.sensor_data.daten) {
            var data = {
                labels: this.props.sensor_data.daten.map((elem) => { return elem.zeit * 1000 }),
                datasets: [{
                    data: this.props.sensor_data.daten.map((elem) => { return elem.wert }),
                    borderColor: '#9A92D1',
                    backgroundColor: '#9A92D1',
                    fill: {
                        target: "origin",
                        below: '#DEDBF0'
                    },
                    showLine: false
                }]
            };
            var options = {
                plugins: {
                    legend: {
                        display: false
                    }
                },
                scales: {
                    y: {
                        ticks: {
                            callback: (value, index, ticks) => {
                                return '' + value + '' + this.props.sensor_data.einheit;
                            }
                        },
                        min: y_min,
                        max: y_max
                    },
                    x: {
                        type: "time",
                        time: {
                            minUnit: "minute",
                            displayFormats: {
                                minute: "HH:mm",
                                hour: "HH:00",
                                day: "DD.MM."
                            },
                            tooltipFormat: "dddd DD.MM.YYYY HH:mm"
                        },
                        max: moment().valueOf(),
                        min: moment().subtract(this.props.dauer, "hour").valueOf(),
                        grid: {
                            display: false
                        }
                    },
                },
                elements: {
                    point: {
                        radius: 2
                    }
                }
            }

            return <div className="TempCurve" title={this.props.sensor_data.beschreibung}>
                <div className="TempCurve-Caption">{this.props.sensor_data.name}</div>
                <div className="TempCurve-Value">
                    {get_latest_temp(this.props.sensor_data.daten)}
                    {this.props.sensor_data.einheit}
                    <span className="TempCurve-ValueTime">
                        am {get_latest_time(this.props.sensor_data.daten)}Uhr
                    </span>
                </div>
                <div className="TempCurve-Canvas">
                    <Scatter data={data} options={options} />
                </div>
            </div>;
        } else {
            return <div className="TempCurve" title="Daten werden geladen...">
                <div className="TempCurve-Caption">Wird geladen...</div>
            </div>;
        }
    }
}


/**
 * Ermittle letzte bekannten Wert einer Datenreihe für numerische Anzeige
 * @param {Array<number>} temps 
 * @return {Number}
 */
function get_latest_temp(temps) {
    if (temps[0] === undefined) {
        return null
    }
    var latest_time = temps[0].zeit;
    var latest_temp = temps[0].wert;

    temps.forEach((elem) => {
        if (elem.zeit > latest_time) {
            latest_time = elem.zeit;
            latest_temp = elem.wert;
        }
    });

    return latest_temp.toFixed(1)
}


/**
 * Ermittle Zeitstempel des neuesten Eintrags der Datenreihe
 * @param {Array<number>} temps 
 * @returns {String}
 */
function get_latest_time(temps) {
    if (temps[0] === undefined) {
        return null
    }
    var latest_time = temps[0].zeit;

    temps.forEach((elem) => {
        if (elem.zeit > latest_time) {
            latest_time = elem.zeit;
        }
    });

    return moment.unix(latest_time).format("DD.MM. HH:mm")
}

export default TempCurve
