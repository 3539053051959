import React from 'react';
import './CurvePanel.css'
import TempCurve from './TempCurve';
import { get_all_sensor_ids, get_sensor_data } from '../Service/ServerInterface'
import moment from 'moment';

/** Definition der Konstanten */
const caption = "Kirchen Thermometer"  // Überschrift auf Website

/** Definition der möglichen Zeitspannen: [Anzahl Stunden, Beschriftung] */
const optionen = new Map([
    [6, "Letzte 6 Stunden"],
    [24, "Letzte 24 Stunden"],
    [168, "Letzte Woche"],
    [672, "Letzter Monat"],
]);
/** Anzahl Stunden der default Option */
const default_option = 24

/** Update-Interval in Minuten */
const update_minutes = 5

/**
 * React-Komponente für eine der auswählbaren Zeitspannen
 */
class NavOption extends React.Component {
    // 
    render() {
        return <div className={
            "Option" + (this.props.aktiv ? " aktiv" : "")
        } onClick={() => this.props.parent.setDuration(this.props.hours)}>{this.props.name}</div>
    }
}


/**
 * React-Komponente für Panel, mit den Temperaturgraphen und den auswählbaren Zeitspannnen.
 */
class CurvePanel extends React.Component {

    /**
     * Setze alle verfügbaren Sensor-IDs
     * @param {Array<string>} sensor_ids 
     */
    setAllSensorIds(sensor_ids) {
        console.log('Set all sensor ids: ' + sensor_ids)
        this.setState({ all_sensor_ids: sensor_ids });

        this.fetchSensorData();
    }

    /**
     * Setze alle Daten zu einem sensor
     * @param {*} sensor_data 
     */
    setSensorData(sensor_data) {
        console.log('Set sensor data for ' + sensor_data.id + ' : ' + sensor_data);
        let data = this.state.all_sensor_data;
        data[sensor_data.id] = sensor_data
        this.setState({ all_sensor_data: data })

        this.calcMinMaxData()
    }

    /**
     * Berechne Min und Max für einzelne Einheiten aus allen verfügbaren Daten, 
     * damit Graphen mit gleicher Einheit auch die gleiche Skala bekommen.
     */
    calcMinMaxData() {
        let min_max_values = {
            '°C': {
                min: 1,
                max: 19
            }
        }
        Object.values(this.state.all_sensor_data).forEach((s_data, s_id) => {
            if (s_data.daten) {
                s_data.daten.forEach((eintrag) => {
                    if (eintrag.zeit >= moment().subtract(this.state.active_option, "hour").unix()){
                        if (min_max_values[s_data.einheit] === undefined) {
                            min_max_values[s_data.einheit] = {
                                min: eintrag.wert,
                                max: eintrag.wert
                            };
                        }
                        if (min_max_values[s_data.einheit].min > eintrag.wert) {
                            min_max_values[s_data.einheit].min = eintrag.wert;
                        }
                        if (min_max_values[s_data.einheit].max < eintrag.wert) {
                            min_max_values[s_data.einheit].max = eintrag.wert;
                        }
                    }
                });
            }
        });
        Object.values(min_max_values).forEach((min_max) => {
            min_max.min = Math.floor(min_max.min - 0.1);
            min_max.max = Math.ceil(min_max.max + 0.1);
        });

        this.setState({ min_max_values: min_max_values });
    }

    /**
     * Starte Abfrage der Daten für alle Sensoren
     */
    fetchSensorData() {
        this.state.all_sensor_ids.forEach((s_id) => {
            let t_from = moment().subtract(this.state.active_option, "hour").unix();
            let t_to = moment().unix();
            get_sensor_data(s_id, t_from, t_to, this.setSensorData.bind(this));
        });
    }

    constructor(props) {
        super(props);

        this.state = {
            active_option: default_option,
            all_sensor_ids: [],
            all_sensor_data: {},
            min_max_values: {}
        };

        get_all_sensor_ids(this.setAllSensorIds.bind(this));
        setInterval(() => {
            get_all_sensor_ids(this.setAllSensorIds.bind(this));
        }, 1000 * 60 * update_minutes);
    }

    /**
     * Setze eine der auswählbaren Zeitspannen
     * @param {Number} hours Anzahl der Stunden
     */
    setDuration(hours) {
        this.setState({ active_option: hours });
        get_all_sensor_ids(this.setAllSensorIds.bind(this));
    }

    render() {
        console.log(this.state.min_max_values)

        var nav_options = [];

        optionen.forEach((val, key) => {
            nav_options.push(<NavOption
                name={val}
                key={key}
                hours={key}
                aktiv={key === this.state.active_option}
                parent={this}
            />);
        });

        var temp_curves = [];

        Object.values(this.state.all_sensor_data).forEach((s_data, s_id) => {
            temp_curves.push(<TempCurve key={s_data.id}
                sensor_data={s_data}
                dauer={this.state.active_option}
                min_max={this.state.min_max_values}
            />);
        })

        if (temp_curves) { } else {
            temp_curves.push(<div>Laden...</div>)
        }

        return <div className='CurvePanel'>
            <div className="NavPanel">
                <div className="Colorbox"></div>
                <div className="Caption">{caption}</div>
                <div className="Optionen">
                    {nav_options}
                </div>
            </div>
            <div className="WidgetPanel">{temp_curves}</div>
        </div>
    }
}

export default CurvePanel
