import moment from "moment"  // Nur für Demobetrieb nötig, um aktuelle Zeitstempel zu generieren

const demo = false;  // Für Demobetrieb auf true setzen

/* Api-Base-URL des Backend-Servers (inkl abschließendem Slash) */
const api_url = 'https://kirchenthermometer.pfachs.de/api/'

/**
 * Abfrage aller verfügbaren Sensoren
 * @param {Function} cb_func Callback, über den das Ergebnis asynchron zurückgegeben wird
 */
function get_all_sensor_ids(cb_func) {
    if (!demo) {
        // Livebetrieb
        fetch(api_url + 'sensor')
        .then(response => response.json())
        .then(data => cb_func(data));
    } else {
        // Demo-Werte für Offline-Betrieb
        setTimeout(() => {
            cb_func(["Pdf1", "Pdf2", "Etd_A", 'ABC123']);    
        }, 500);
    }
}

/**
 * Abfrage aller Werte für einen Sensor
 * @param {string} id Id des 
 * @param {Function} cb_func Callback, über den das Ergebnis asynchron zurückgegeben wird
 */
function get_sensor_data(id, t_from, t_to, cb_func) {
    if (!demo) {
        // Livebetrieb
        fetch(api_url + 'sensor/' + id + '?t_from=' + t_from + '&t_to=' + t_to)
        .then(response => response.json())
        .then(data => cb_func(data));
    } else {
        // Demo-Werte für Offline-Betrieb
        setTimeout(() => {
            if (id === 'ABC123') {
                return {
                    id: "ABC123",
                    name: "Dummy",
                    beschreibung: "ein wirklicher Dummie",
                    einheit: "K",
                    daten: []
                }
            }
            if (id === 'Pdf1') {
                cb_func({
                    "id": "Pdf1",
                    "name": "Pappendorf 1",
                    "beschreibung": "Temperaturensor in Reihe 1 in Pdf",
                    "einheit": "°C",
                    "daten": [
                        {
                            "zeit": moment().subtract(1, "minute").unix(),
                            "wert": 7.2
                        },
                        {
                            "zeit": moment().subtract(62, "minute").unix(),
                            "wert": 8.1
                        },
                        {
                            "zeit": moment().subtract(110, "minute").unix(),
                            "wert": 9.7
                        },
                        {
                            "zeit": moment().subtract(145, "minute").unix(),
                            "wert": 12.2
                        },
                        {
                            "zeit": moment().subtract(210, "minute").unix(),
                            "wert": 12.5
                        },
                        {
                            "zeit": moment().subtract(331, "minute").unix(),
                            "wert": 11.0
                        },
                    ]
                });
            }
            if (id === 'Pdf2') {
                cb_func({
                    "id": "Pdf2",
                    "name": "Pappendorf 2",
                    "beschreibung": "Temperaturensor 2 in Pdf",
                    "einheit": "K",
                    "daten": [
                        {
                            "zeit": moment().subtract(21, "minute").unix(),
                            "wert": 317.5
                        },
                        {
                            "zeit": moment().subtract(36, "minute").unix(),
                            "wert": 318.2
                        },
                        {
                            "zeit": moment().subtract(51, "minute").unix(),
                            "wert": 319.1
                        },
                        {
                            "zeit": moment().subtract(66, "minute").unix(),
                            "wert": 321.2
                        },
                        {
                            "zeit": moment().subtract(81, "minute").unix(),
                            "wert": 322.9
                        },
                        {
                            "zeit": moment().subtract(96, "minute").unix(),
                            "wert": 319.0
                        },
                    ]
                });
            }
            if (id === 'Etd_A') {
                cb_func({
                    "id": "Etd_A",
                    "name": "Etzdorf A",
                    "beschreibung": "Temperaturensor A in Etzdorf",
                    "einheit": "°C",
                    "daten": [
                        {
                            "zeit": moment().subtract(1, "hour").unix(),
                            "wert": 0.210002
                        },
                        {
                            "zeit": moment().subtract(25, "hour").unix(),
                            "wert": 2.2
                        },
                        {
                            "zeit": moment().subtract(50, "hour").unix(),
                            "wert": 5.1
                        },
                        {
                            "zeit": moment().subtract(75, "hour").unix(),
                            "wert": 8.9
                        },
                        {
                            "zeit": moment().subtract(100, "hour").unix(),
                            "wert": 10.1
                        },
                        {
                            "zeit": moment().subtract(150, "hour").unix(),
                            "wert": 7.2
                        },
                    ]
                });
            }
        }, 500);
    }
}

export { get_all_sensor_ids, get_sensor_data };
